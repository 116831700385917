import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import SideBar from "./SideBar";
import Footer from "../Footer";

const Music = () => {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | Music's Section`;
  }, [name]);
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setRole(data.role);

      // Hide Others ID
      var saurin = document.getElementById("saurin-files");
      var lekha = document.getElementById("lekha-files");
      var soumwadeep = document.getElementById("soumwadeep-files");
      var others = document.getElementById("others-files");
      if (data.role === "adminlekha") {
        saurin.style.display = "none";
        soumwadeep.style.display = "none";
        others.style.display = "none";
      } else if (data.role === "adminsoumwadeep") {
        saurin.style.display = "none";
        lekha.style.display = "none";
        others.style.display = "none";
      } else if (data.role === "adminsaurin") {
        lekha.style.display = "none";
        soumwadeep.style.display = "none";
        others.style.display = "none";
      } else {
        lekha.style.display = "none";
        soumwadeep.style.display = "none";
        saurin.style.display = "none";
      }
    } catch (err) {
      logout();
      swal("Login Failed!", "Please Try To Login Again!", "error");
      return navigate("/");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  // Authentication Ends

  return (
    <>
      <SideBar />
      <section id="Music">
        <div className="container">
          <h1>Music's Section</h1>
          <p>
            Welcome To Z-Cloud's Music's Section.Here You Will Get All The Songs
            Of 80's Artist's Songs To Present Artist's Songs.
          </p>
          <div id="saurin-files">
            <div className="row">
              <div className="col-sm">
                <h3>Kishore Kumar Songs:</h3>
                <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/KishoreKumar/"
                  title="Kishore Kumar"
                  className="iframecss"
                />
              </div>
              <div className="col-sm">
                <h3>Manna Dey Songs:</h3>
                <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/MannaDey/"
                  title="Manna Dey"
                  className="iframecss"
                />
              </div>
            </div>
            <div className="row">
              <center>
                <div className="col-sm">
                  <h3>Hemanta Mukherjee Songs:</h3>
                  <iframe
                    src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/HemantaMukherjee/"
                    title="Hemanta Mukherjee"
                    className="iframecss"
                  />
                </div>
              </center>
            </div>
          </div>
          <div id="lekha-files">
            <div className="row">
              <center>
                <div className="col-sm">
                  <h3>Devotional Songs:</h3>
                  <iframe
                    src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/Devotional/"
                    title="Devotional"
                    className="iframecss"
                  />
                </div>
              </center>
            </div>
          </div>
          <div id="soumwadeep-files">
            <div className="row">
              <div className="col-sm">
                <h3>Presently We Are Working On It!</h3>
                {/* <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/Devotional/"
                  title="Kishore Kumar"
                  className="iframecss"
                /> */}
              </div>
            </div>
          </div>
          <div id="others-files">
            <div className="row">
              <div className="col-sm">
                <h3>Presently We Are Working On It!</h3>
                {/* <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/MusicPlayer/Devotional/"
                  title="Kishore Kumar"
                  className="iframecss"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default Music;
