import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import SideBar from "./SideBar";
import Footer from "../Footer";

const Documents = () => {
  // Authentication Starts
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | Document's Section`;
  }, [name]);
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setRole(data.role);
      var others = document.getElementById("check-role");
      if(!data.role)
      {
        others.style.display = "none";
      }
    }
    catch (err) {
      logout();
      swal(
        "Login Failed!",
        "Please Try To Login Again!",
        "error"
      );
      // alert(err);
      return navigate("/");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  // Authentication Ends
  // If Statements
  var DocumentsLink,UploadLink;
  if (role === "adminlekha") 
  {
    DocumentsLink = "1VAqZsHmTgjcdwa1UiU4uE-bP-Qlz6OHS";
    UploadLink="1VAqZsHmTgjcdwa1UiU4uE-bP-Qlz6OHS";
  }
  else if(role === "adminsoumwadeep")
  {
    DocumentsLink = "1afXgakAGyf36Jip3ah0PBwWfjnyqoiqo";
    UploadLink="1afXgakAGyf36Jip3ah0PBwWfjnyqoiqo";
  }
  else if(role === "adminsaurin")
  {
    DocumentsLink = "1pPQrLDw7W84337mnELofgbvZX-2Fp-1B";
    UploadLink="1pPQrLDw7W84337mnELofgbvZX-2Fp-1B";
  }
  else
  {
    DocumentsLink = "15f_oaO_MuoGime4pELL1TQr5jjt6HZba";
  }
  function goToUpload()
  {
    window.open(`https://drive.google.com/drive/folders/${UploadLink}?usp=share_link`);
  }
  return (
    <>
      <SideBar />
      <section id="Documents">
        <div className="container">
          <h1>Document's Section</h1>
          <p>
            Welcome To Z-Cloud's Document's Section.Here You Will Get All The Latest Documents Such As Articles,Notes,Books,etc.
          </p>
          <h3 id="show-heading">Latest Documents</h3>
          <div id="documents-iframe">
            <iframe
              src={`https://drive.google.com/embeddedfolderview?id=${DocumentsLink}#grid`}
              className="iframecss"
              title="documents"
            >
            </iframe>
            <br/>
            <center>
              <button className="uploadbtn" id="check-role" onClick={goToUpload}>Upload New</button>
            </center>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default Documents;
