import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, NavLink } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import "../../css/SideBar.css";
import Footer from "../Footer";
const SideBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  function toggleNav() {
    setNavOpen((state) => !state);
  }
  // Authentication Starts
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | Z-Cloud`;
  }, [name]);
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      logout();
      swal(
        "Login Failed!",
        "Please Try To Login Again!",
        "error"
      );
      return navigate("/");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  // Authentication Ends
  return (
    <>
      <button
        onClick={toggleNav}
        className="btn mt-3 mx-3 sticky-top"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions"
      >
        Menu =&gt;
      </button>

      <div
        className="offcanvas offcanvas-start offcanvas-dashboard"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
        data-bs-dismiss={navOpen ? "offcanvas" : "none"}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            Z-Cloud
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <NavLink
            to="/Documents"
            className="nav-link"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              toggleNav();
            }}
          >
            Documents
          </NavLink>
          <NavLink
            to="/Music"
            className="nav-link"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              toggleNav();
            }}
          >
            Music
          </NavLink>
          <NavLink
            to="/Videos"
            className="nav-link"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              toggleNav();
            }}
          >
            Videos
          </NavLink>
          <NavLink
            to="/Softwares"
            className="nav-link"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              toggleNav();
            }}
          >
            Softwares
          </NavLink>
          <NavLink
            to="/Games"
            className="nav-link"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              toggleNav();
            }}
          >
            Games
          </NavLink>
          <NavLink
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              logout();
              toggleNav();
            }}
            className="nav-link"
          >
            Log Out
          </NavLink>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default SideBar;
