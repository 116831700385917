import React from "react";
import features from "../images/features.jpg";
import documents from "../images/documents.jpg";
import music from "../images/music.jpg";
import videos from "../images/videos.jpg";
import software from "../images/software.jpg";
import games from "../images/games.jpg";
import extras from "../images/extras.jpg";
import { NavLink } from "react-router-dom";
import "../css/Features.css";
const Features = () => {
  return (
    <>
      <section id="Features">
        <div className="container">
          <h1 className="fixh1">Features</h1>
          <div className="row">
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <p>
                      We Provide You The Access Of Almost All The Types Of
                      Resources Without The Need To Download It i.e. You Can
                      Access It Directly From Our Website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <img
                      src={features}
                      alt="Features"
                      className="img-fluid circularimg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center>
            <div className="row">
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={documents} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Documents</h5>
                      <p className="card-text">
                        We Provide You The Access Of All The Types Of Documents
                        Available Without The Need To Download It.
                      </p>
                      <center>
                        <NavLink to="/Documents" className="btn">
                          Go To Docs Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={music} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Music</h5>
                      <p className="card-text">
                      We Provide You The Access Of All The Types Of Music Available Without The Need To Download It.
                      </p>
                      <center>
                        <NavLink to="/Music" className="btn">
                          Go To Music Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={videos} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Videos</h5>
                      <p className="card-text">
                      We Provide You The Access Of All The Types Of Videos Available Without The Need To Download It.
                      </p>
                      <center>
                        <NavLink to="/Videos" className="btn">
                          Go To Videos Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={software} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Softwares</h5>
                      <p className="card-text">
                      We Provide You The Access Of All The Types Of Mobile Applications And Computer Softwares Whether Its Premium Or Free To Download.
                      </p>
                      <center>
                        <NavLink to="/Softwares" className="btn">
                          Go To Software's Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={games} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Games</h5>
                      <p className="card-text">
                      We Provide You The Access Of All The Types Of Mobile And Computer Games Whether Its Paid Or Free To Download.
                      </p>
                      <center>
                        <NavLink to="/Games" className="btn">
                          Go To Games' Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="feature-cards">
                  <div className="card">
                    <img src={extras} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Extras</h5>
                      <p className="card-text">
                        We Always Love To Surprise Our Users So We Also Provide Lots Of More Resources And Even Give Our Users Some Free Personal Storage Folder Too.
                      </p>
                      <center>
                        <NavLink to="/Extras" className="btn">
                          Go To Extras' Section
                        </NavLink>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </section>
    </>
  );
};

export default Features;
