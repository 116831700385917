import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import SideBar from "./SideBar";
import "./VideoPlayer.css";
import Footer from "../Footer";

const Videos = () => {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | Videos's Section`;
  }, [name]);
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setRole(data.role);

      // Hide Others ID
      var saurin = document.getElementById("saurin-files");
      var lekha = document.getElementById("lekha-files");
      var soumwadeep = document.getElementById("soumwadeep-files");
      var others = document.getElementById("others-files");
      if (data.role === "adminlekha") {
        saurin.style.display = "none";
        soumwadeep.style.display = "none";
        others.style.display = "none";
      } else if (data.role === "adminsoumwadeep") {
        saurin.style.display = "none";
        lekha.style.display = "none";
        others.style.display = "none";
      } else if (data.role === "adminsaurin") {
        lekha.style.display = "none";
        soumwadeep.style.display = "none";
        others.style.display = "none";
      } else {
        lekha.style.display = "none";
        soumwadeep.style.display = "none";
        saurin.style.display = "none";
      }
    } catch (err) {
      logout();
      swal("Login Failed!", "Please Try To Login Again!", "error");
      return navigate("/");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  // Authentication Ends

  return (
    <>
      <SideBar />
      <section id="Videos">
        <div className="container">
          <h1>Videos's Section</h1>
          <p>
            Welcome To Z-Cloud's Videos's Section.Here You Will Get All Your
            Favourite Videos,Series etc.
          </p>
          <div id="saurin-files">
            {/* <div className="row">
              <div className="col-sm">
                <h3>Kishore Kumar Songs:</h3>
                <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/VideosPlayer/KishoreKumar/"
                  title="Kishore Kumar"
                  className="iframecss"
                />
              </div>
              <div className="col-sm">
                <h3>Manna Dey Songs:</h3>
                <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/VideosPlayer/MannaDey/"
                  title="Manna Dey"
                  className="iframecss"
                />
              </div>
            </div>
            <div className="row">
              <center>
                <div className="col-sm">
                  <h3>Hemanta Mukherjee Songs:</h3>
                  <iframe
                    src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/VideosPlayer/HemantaMukherjee/"
                    title="Hemanta Mukherjee"
                    className="iframecss"
                  />
                </div>
              </center>
            </div> */}
            <h1>Presently We Are Working On It!</h1>
          </div>
          <div id="lekha-files">
            <div className="row">
              <h3>Maa Vaishno Devi:</h3>
              <iframe
                className="vidiframecss"
                src="https://www.youtube.com/embed/videoseries?list=PLIDhtHVHgoB1jEaosxB-oaSmq7Yfgynm5"
                title="Maa Vaishno Devi Aarti"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <div className="row">
              <h3>Kaalchakra:</h3>
              <iframe
                className="vidiframecss"
                src="https://www.youtube.com/embed/videoseries?list=PLIDhtHVHgoB0hiTdg9FGDSvlhfUJgAzIS"
                title="Kaalchakra"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <div className="row">
              <div className="col-sm">
                <h3>Full Bhagawad Geeta</h3>
                <iframe
                  src="https://drive.google.com/file/d/1H0MeA92ih2o_gMnopDPd5WbKX4NUaTId/preview"
                  className="vidiframecss"
                  allow="autoplay"
                ></iframe>
              </div>
              <div className="col-sm" id="timestamps">
                <h3>Time Stamps:</h3>
                <p>
                  <span>00:00:05</span> Arjuna vishada yoga, Prathama Adhyaya
                  Intro
                </p>
                <p>
                  <span>00:50:14</span> Sankhya Yoga Verse Intro
                </p>
                <p>
                  <span>02:14:32</span> Karma Yoga Verse Intro
                </p>
                <p>
                  <span>03:04:59</span> Ana Karma Sanyasa Yoga Intro
                </p>
                <p>
                  <span>03:55:43</span> Karma Sanyasa Yoga Intro
                </p>
                <p>
                  <span>04:30:36</span> Dhyana Yoga Intro
                </p>
                <p>
                  <span>05:25:40</span> Jnana Vijnana Yoga Intro & 01
                </p>
                <p>
                  <span>06:02:00</span> Aksara Brahma Yoga Intro
                </p>
                <p>
                  <span>06:36:10</span> Raja Vidya Raja Guhya Yoga Intro
                </p>
                <p>
                  <span>07:19:55</span> Vibhuti Vistara Yoga Intro
                </p>
                <p>
                  <span>08:11:02</span> Visvarupa Darsana Yoga Intro
                </p>
                <p>
                  <span>09:18:10</span> Bhakti Yoga Intro
                </p>
                <p>
                  <span>09:41:53</span> Ksetra Ksetrajna Vibhaga Yoga Intro
                </p>
                <p>
                  <span>10:26:34</span> Gunatraya Vibhaga Yoga Intro
                </p>
                <p>
                  <span>11:00:59</span> Purusottama Yoga Intro
                </p>
                <p>
                  <span>11:28:57</span> Daivasura Sampad Vibhaga Yoga Intro
                </p>
                <p>
                  <span>12:00:18</span> Sraddhatraya Vibhaga Yoga Intro
                </p>
                <p>
                  <span>12:37:39</span> Moksha Sanyasa Yoga Intro
                </p>
              </div>
            </div>
          </div>
          {/* Soumwadeep */}
          <div id="soumwadeep-files">
            <h3>Subjects</h3>
            <div className="row">
              <div className="col-sm">
                <h4>Compiler Design:</h4>
                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PLBlnK6fEyqRjT3oJxFXRgjPNzeS-LFY-q"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-sm">
                <h4>Software Engineering:</h4>

                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PLxCzCOWd7aiEed7SKZBnC6ypFDWYLRvB2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h4>Artificial Intelligence:</h4>
                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PLxCzCOWd7aiHGhOHV-nwb0HR5US5GFKFI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-sm">
                <h4>Operating Systems:</h4>

                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PLBlnK6fEyqRiVhbXDGLXDk_OQAeuVcp2O"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h4>Introduction To Industrial Management:</h4>
                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PL3oWeJK3GDfxArIr9NF4V1LSvbF0_i1W-"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-sm">
                <h4>Object Oriented Programming(JAVA):</h4>

                <iframe
                  className="vidiframecss"
                  src="https://www.youtube.com/embed/videoseries?list=PLfqMhTWNBTe3LtFWcvwpqTkUSlB32kJop"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div id="others-files">
            <div className="row">
              <div className="col-sm">
                <h3>Presently We Are Working On It!</h3>
                {/* <iframe
                  src="https://soumwadeep.github.io/Z-Cloud/src/components/DashBoard/VideosPlayer/Devotional/"
                  title="Kishore Kumar"
                  className="iframecss"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default Videos;
