import React from "react";
import home from "../images/home.png";
const Header = () => {
  return (
    <>
      <section id="Header">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <h1>Welcome To Z Cloud</h1>
                    <h4>
                      India's First And Only Online Platform For All Your Daily
                      Needs.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <img src={home} alt="Home" className="img-fluid" id="animateimg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
