import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import SideBar from "./SideBar";
import Footer from "../Footer";

const Softwares = () => {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | Softwares's Section`;
  }, [name]);
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setRole(data.role);
    } catch (err) {
      logout();
      swal("Login Failed!", "Please Try To Login Again!", "error");
      return navigate("/");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  // Authentication Ends
  // If Statements
  var SoftwaresLink;
  if (role === "adminlekha") {
    SoftwaresLink = "1AtYoHSZxOmz8bzpg3njCrqMqf20l9lWG";
  } else if (role === "adminsoumwadeep") {
    SoftwaresLink = "1l5ma2KAJHMETdj_hf_xFs_2fPmJJKA0b";
  } else if (role === "adminsaurin") {
    SoftwaresLink = "12iZnsC5QAuarGhGsRURLy5fRBlnwf8tU";
  } else {
    SoftwaresLink = "15yPXsMUU5WVOkx7dWI9VkwOaxBVmRftl";
  }
  return (
    <>
      <SideBar />
      <section id="Softwares">
        <div className="container">
          <h1>Softwares's Section</h1>
          <p>
            Welcome To Z-Cloud's Softwares's Section.Here You Will Get All Your
            Required Softwares Such As MS Office, IDM etc.
          </p>
          <div id="saurin-files">
            <h3 id="show-heading">Latest Available Softwares</h3>
            <div id="softwares-iframe">
              <iframe
                src={`https://drive.google.com/embeddedfolderview?id=${SoftwaresLink}#grid`}
                className="iframecss"
                title="softwares"
              ></iframe>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default Softwares;
