import { initializeApp } from "firebase/app";
import swal from "sweetalert";

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyCPGP6uKDsCIMZRf7-SwiRqy2FivUwLs90",
    authDomain: "z-cloud-2d41c.firebaseapp.com",
    projectId: "z-cloud-2d41c",
    storageBucket: "z-cloud-2d41c.appspot.com",
    messagingSenderId: "664723473380",
    appId: "1:664723473380:web:49a965b2aac49e60bd3df1",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "GoogleUsers"), where("uid", "==", user.uid));
        const docs = await getDocs(q);

        var today = new Date();
        var curHr = today.getHours();
        if (curHr < 12) {
            var greet = "Good Morning";
        } else if (curHr < 18) {
            greet = "Good Afternoon";
        } else {
            greet = "Good Evening";
        }

        if (docs.docs.length === 0) {
            await addDoc(collection(db, "GoogleUsers"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
            swal(
                `${greet} ${user.displayName}`, "Welcome To Our Dashboard!", "success"
            );
        }
    } catch (err) {
        console.error(err);
        swal("Error!", `${err.message}`, "error");
    }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    signInWithGoogle,
    logout,
};