import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import swal from "sweetalert";
import SideBar from "./SideBar";
import Footer from "../Footer";

const DashboardHome = () => {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Welcome ${name} | ZCloud`;
  }, [name]);
  var today = new Date();
  var curHr = today.getHours();
  if (curHr < 12) {
    var greet = "Good Morning";
  } else if (curHr < 16) {
    greet = "Good Afternoon";
  } else {
    greet = "Good Evening";
  }

  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "GoogleUsers"),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      swal(`${greet} ${data.name}`, "Welcome To Our Dashboard!", "success");
    } catch (err) {
      logout();
      swal(
        "Login Failed!",
        "Please Try To Login Again!",
        "error"
      );
      return navigate("/");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    // eslint-disable-next-line
  }, [user, loading, navigate]);
  return (
    <>  
    <SideBar/>
    <section id="dashboard">
      <div className="container">
      <h1 className="text-center">Hello! Welcome To Z-Cloud's Dashboard!</h1>
      <h4>How To Use Our Dashboard Now?</h4><br/>
      <h5>Just Follow This 3 Simple Instructions To Get Started:</h5><br/>
      <p>1. Click On The Menu Button Present Above This.</p>
      <p>2. Now Look For Your Favourite Section And Just Click On That Option.</p>
      <p>3. That's It! Now Your Favourite Section Will Open And From There You Can Enjoy Our Awesome Stuffs!</p><br/>
      <h3>Enjoy To The Fullest !!</h3>
      </div>
      <Footer/>
      </section>
    </>
  );
};

export default DashboardHome;
