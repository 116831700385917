import React from "react";
import { Link } from "react-scroll";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../images/icon.png";
import gbtn from "../images/gbtn.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth,signInWithGoogle } from "../firebase";
import "../css/NavBar.css";
const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  function toggleNav() {
    setNavOpen((state) => !state);
  }
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/Dashboard");
  }, [user, loading, navigate]);
  return (
    <>
      <nav className="navbar navbar-dark sticky-top navbar-expand-lg text-center">
        <div className="container-fluid">
          <Link
            activeClass="nav-link active"
            to="Home"
            spy={true}
            smooth={false}
            className="navbar-brand"
          >
            <img src={icon} alt="Logo" className="nav-icon" /> Cloud
          </Link>
          <button
            onClick={toggleNav}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="navbarOffcanvasNavbarLabel"
            data-bs-dismiss={navOpen ? "offcanvas" : "none"}
          >
            <div className="offcanvas-header">
              <Link
                to="Home"
                spy={true}
                smooth={false}
                style={{ textDecoration: "none", color: "white" }}
                onClick={toggleNav}
              >
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img src={icon} alt="Logo" className="nav-icon" /> Cloud
                </h5>
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to="Header"
                    spy={true}
                    smooth={false}
                    className="nav-link"
                    onClick={toggleNav}
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="About"
                    spy={true}
                    smooth={false}
                    className="nav-link"
                    onClick={toggleNav}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="Features"
                    spy={true}
                    smooth={false}
                    className="nav-link"
                    onClick={toggleNav}
                  >
                    Features
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="Contact"
                    spy={true}
                    smooth={false}
                    className="nav-link"
                    onClick={toggleNav}
                  >
                    Contact
                  </Link>
                </li>
                <li className="nav-item login-btn">
                  <Link
                    to="Login"
                    spy={true}
                    smooth={false}
                    className="nav-link"
                    style={{color:"white"}}
                    // onClick={toggleNav}
                    onClick={signInWithGoogle}
                  >
                    <img src={gbtn} className="gbtn-fix" alt="google icon"/>
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
