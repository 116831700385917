import React from 'react'
import Footer from '../Footer'

const Extras = () => {
  return (
    <>
        <section id="Extras">
            <div className="container">
                <h1>Extras' Section</h1>
                <p>We Are Presently Working On This. It Will Soon Be Completed!</p>
            </div>
            <Footer/>
        </section>
    </>
  )
}

export default Extras
