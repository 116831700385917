import React, { useEffect } from "react";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import About from "./components/About";
import Features from "./components/Features";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
const Home = () => {
  useEffect(() => {
    document.title = `Welcome To Z-Cloud`;
  }, []);
  return (
    <>
      <section id="Home">
        <NavBar />
        <Header />
        <About />
        <Features />
        <Contact />
        <Footer />
      </section>
    </>
  );
};

export default Home;
