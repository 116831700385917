import React from "react";
import Footer from "../Footer";
import TicTacToe from "./Games/TicTacToe/TicTacToe";
import SideBar from "./SideBar";

const Games = () => {
  return (
    <>
      <SideBar />
      <section id="Games">
        <div className="container">
          <h1>Games' Section</h1>
          <p>
            Welcome To Z-Cloud's Game's Section.Here You Will Get All The Latest
            Paid Games For Free And Also Some Games Which Can Be Played In Our
            Web App Itself.
          </p>
          <h1 className="text-center">TIC-TAC-TOE</h1>
          <TicTacToe />
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default Games;
