import React from "react";
import "./ResetButton.css";

export const ResetButton = ({ resetBoard }) => {
    function restartGame() 
    {
        window.location.reload(false);
    }
  return (
    <>
        <center>
        <button className="reset-btn" onClick={resetBoard}>
        Reset
        </button>
        <button className="reset-btn" onClick={restartGame}>
        Restart
        </button>
        </center>
    </>
  );
};
