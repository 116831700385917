import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import thanks from "../images/thanks.jpg";
import Footer from "./Footer";
const ThankYou = () => {
  useEffect(() => {
    document.title = `Thank You!`;
  }, []);
  return (
    <>
      <div id="ThankYou">
        <div className="container">
          <h1 className="fixh1">We Have Received Your Message</h1>
          <div className="row">
            <div className="col-sm-4">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <h3>
                      Thank You For Contacting Us. We Will Soon Get Back To You.
                    </h3>
                    <NavLink to="/" className="btn">
                      Go Back To Home
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <br />
                    <img
                      src={thanks}
                      alt="Thanks"
                      className="circularimg"
                      id="animateimg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ThankYou;
