import React from "react";
import about from "../images/about.jpg";
const About = () => {
  return (
    <>
      <section id="About">
        <div className="container">
          <h1 className="fixh1">About Us</h1>
          <div className="row">
            <div className="col-sm-5">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <img
                      src={about}
                      alt="About"
                      className="img-fluid circularimg"
                    //   id="animateimg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <p>
                      Z-Cloud Is Created To Provide All The Users With The Facility Of Getting Access To All The Types Of Digital Resources Available In The Whole World.
                    </p>
                    <p>
                      We Have Planned To Upload All The Latest Articles,Study Materials,Musics Of Every Genres,Movies & Series,Mobile &
                      Pc Softwares And Games.
                    </p>
                    <p>
                      We Have Created Our Own Cloud Storage Platform To Provide You The Access Of All The Types Of Files Available Without The Need To Download It Except Games And Softwares.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
