import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './Home';
import DashboardHome from './components/DashBoard/DashboardHome';
import Documents from './components/DashBoard/Documents';
import Music from './components/DashBoard/Music';
import Videos from './components/DashBoard/Videos';
import Softwares from './components/DashBoard/Softwares';
import Games from './components/DashBoard/Games';
import Extras from './components/DashBoard/Extras';
import ThankYou from './components/ThankYou';

function App() {
  return (
    <>
      <Routes>
      <Route exact path="/Home" element={<Home />} />
      {/* Dashboard Contents */}
      <Route exact path="/Dashboard" element={<DashboardHome />} />
      <Route exact path="/Documents" element={<Documents />} />
      <Route exact path="/Music" element={<Music />} />
      <Route exact path="/Videos" element={<Videos />} />
      <Route exact path="/Softwares" element={<Softwares />} />
      <Route exact path="/Games" element={<Games />} />
      <Route exact path="/Extras" element={<Extras />} />
      <Route exact path="/ThankYou" element={<ThankYou />} />
      {/* Dashboard Content Ends */}
      <Route path="*" element={<Navigate to="/Home" replace />} />
      </Routes>
    </>
  );
}

export default App;
