import React from "react";
import contact from "../images/contact.png";
import "../css/Contact.css";
const Contact = () => {
  return (
    <>
      <section id="Contact">
        <div className="container">
          <h1 className="fixh1">Contact Us</h1>
          <div className="row">
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <img
                      src={contact}
                      className="img-fluid circularimg"
                      alt="Contact"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="outer">
                <div className="middle">
                  <div className="inner">
                    <p>
                      If You Have Any Queries Regarding Our Website Or Any Other
                      Issue Then You Can Contact Us By Filling The Form Below.
                    </p>
                    <p>
                      We Will Try Our Best To Solve Your Issue As Soon As
                      Possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <center>
              <form className="contact-form" action="https://formsubmit.co/soumwadeep@gmail.com" method="POST">
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_next" value="https://thezcloud.in/ThankYou"/>
              <input type="hidden" name="_subject" value="Someone Just Send You A Message From Z-Cloud."></input>
              <input type="hidden" name="_template" value="table"></input>
                <div className="mb-3">
                  <label className="form-label">
                    Your Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Your Phone No.
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="Phone"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Your Email ID:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Your Message:
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="Message"
                    required
                  />
                </div>
                <center>
                  <button type="submit" className="btn">
                    Submit
                  </button>
                </center>
              </form>
            </center>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
